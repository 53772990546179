import hash from 'stable-hash'
import md5 from 'md5'

export const stableMd5Hash = (val) => {
    const weakHash = hash(val)  
    return md5(weakHash)
}

export const stableHash = (val) => {
    return hash(val)  
}

export const md5Hash = (val) => {
    return md5(val)
}