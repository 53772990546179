
/**   
 * https://github.com/Modernizr/Modernizr/blob/08ab91b8280c59337b9c2f1e4af4d7563515a4e4/feature-detects/storage/cookies.js
 * 
 * navigator.cookieEnabled cannot detect custom or nuanced cookie blocking
 * configurations. For example, when blocking cookies via the Advanced
 * Privacy Settings in IE9, it always returns true. And there have been
 * issues in the past with site-specific exceptions.
 * Don't rely on it.
 *
 * try..catch because some in situations `document.cookie` is exposed but throws a
 * SecurityError if you try to access it; e.g. documents created from data URIs
 * or in sandboxed iframes (depending on flags/context)
 */
export const isCookiesSupported = () => {

    try {
        // Create cookie
        document.cookie = 'cookietest=1; SameSite=Strict; "';
        var ret = document.cookie.indexOf('cookietest=') !== -1;
        // Delete cookie
        document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT; SameSite=Strict;';
        return ret;
    }
      catch (e) {
        return false;
    }

}

/**
 * https://github.com/Modernizr/Modernizr/blob/08ab91b8280c59337b9c2f1e4af4d7563515a4e4/feature-detects/storage/localstorage.js
 * 
 * In FF4, if disabled, window.localStorage should === null.
 *
 * Normally, we could not test that directly and need to do a
 *   `('localStorage' in window)` test first because otherwise Firefox will
 *   throw bugzil.la/365772 if cookies are disabled
 *
 * Similarly, in Chrome with "Block third-party cookies and site data" enabled,
 * attempting to access `window.sessionStorage` will throw an exception. crbug.com/357625
 *
 * Also in iOS5 Private Browsing mode, attempting to use localStorage.setItem
 * will throw the exception:
 *   QUOTA_EXCEEDED_ERROR DOM Exception 22.
 * Peculiarly, getItem and removeItem calls do not throw.
 *
 * Because we are forced to try/catch this, we'll go aggressive.
 *
 * Just FWIW: IE8 Compat mode supports these features completely:
 *   www.quirksmode.org/dom/html5.html
 * But IE8 doesn't support either with local files
 */

export const isLocalStorageSupported = () => {
    var mod = 'storageLsTest';
    try {
        localStorage.setItem(mod, mod);
        localStorage.removeItem(mod);
        return true;
    } catch (e) {
        return false;
    }
}

/**
 * https://github.com/Modernizr/Modernizr/blob/08ab91b8280c59337b9c2f1e4af4d7563515a4e4/feature-detects/storage/sessionstorage.js
 *
 * Because we are forced to try/catch this, we'll go aggressive.
 * 
 * Just FWIW: IE8 Compat mode supports these features completely:
 *   www.quirksmode.org/dom/html5.html
 * But IE8 doesn't support either with local files
 */
export const isSessionStorageSupported = () => {
    var mod = 'storageSsTest';
    try {
      sessionStorage.setItem(mod, mod);
      sessionStorage.removeItem(mod);
      return true;
    } catch (e) {
      return false;
    }
}

export const featureDetectsStorageReport = () => {
    let typesNotSupported = ''
    if (!isCookiesSupported()) {
      typesNotSupported += '_c'
    }
    if (!isLocalStorageSupported()) {
      typesNotSupported += '_ls'
    }
    if (!isSessionStorageSupported()) {
      typesNotSupported += '_ss'
    }

    if (typesNotSupported.length > 0) {
        return {
            errors: [typesNotSupported],
            ok: false,
        }
    }

    return {
        ok: true,
        errors: null,
    }
}

export const isStructuredCloneSupported  = () => {
  return typeof structuredClone !== 'undefined'
}