import { notNullNotUndefined } from "./objectUtils";

export const getQueryParamSafe = (key) => {

    if (typeof window === 'undefined') {
        return null;
    }
    if (typeof window.URLSearchParams !== 'undefined') {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(key);
    }

    var qs = (function(a) {
        if (!a) return {};
        var b = {};
        for (var i = 0; i < a.length; ++i)
        {
            var p=a[i].split('=', 2);
            if (p.length == 1)
                b[p[0]] = "";
            else
                b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
        }
        return b;
    })(window.location.search.substr(1).split('&'));
    return qs[key];
}

export const getWindowLocationHrefSafe = () => {

    if (typeof window === 'undefined') {
        return null;
    }

    return window?.location?.href

}

export const insertUrlParam = (key, value) => {
    if (history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({path: newurl}, '', newurl);
    }
}

export const isWindowDefined = () => {
    if (typeof window !== 'undefined') {
        return true
    }
    return false
}

export const isElementHidden = (el) => {
    var style = window.getComputedStyle(el)
    return (style.display === 'none' || style.opacity == '0')
}

/* export const isElementNotExistingOrHiddenUseSelector = (selectors) => {
    notNullNotUndefined(selectors)

    const el = document.querySelector(selectors)
    if (!el) {
        return true
    }

    var style = window.getComputedStyle(el)
    //console.debug('style.opacity', style.opacity)
    //console.debug('style.display', style.display)
    return (style.display === 'none' || style.opacity == '0')
} */

export const isAnyElementExistingAndVisible = (selectors) => {
    notNullNotUndefined(selectors)
    
    const elems = document.querySelectorAll(selectors)
    if (elems.length === 0) {
        return false;
    }
    return Array.from(elems).some(el=>{
        const style = window.getComputedStyle(el)
        return style.display !== 'none' && style.opacity != '0'
    })
}

export const scrollToElementByClassName = (className: string, offset: number = 0): void => {
    if (className === undefined || className === null) throw Error('object is null or undefined')

    const element = document.querySelector(`.${className}`);

    if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;

        window.scrollTo({
            top: elementPosition + offset,
            behavior: 'smooth'
        });
    } else {
        console.warn(`Element with class name ${className} not found.`);
    }
}