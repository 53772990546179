const I18NextHttpBackend = require('i18next-http-backend');

//must be here to be replaced during compile
const environment = process.env.NEXT_PUBLIC_ENVIRONMENT

module.exports = {
    i18n: {
      localeDetection: false,
      debug: false,
      ns: ['common', 'yoio', 'vsprio', 'gtf'],
      defaultLocale: 'en',
      locales: ['en', 'de'],
      keySeparator: false,
      nsSeparator: ':',
      fallbackLng: "en",
      //preload: , //no idea if that has any effect
      backend: {
        loadPath: process.env.APP_I18NNEXT_LOADPATH,
        customHeaders: {
          Host: process.env.APP_I18NNEXT_LOADPATH_HEADER_HOST,
        },
        request: function (options, url, payload, callback) {
          
          /**
           * Library is doing calls for i18n from client. 
           * This app always preloads all i18n getServerSideProps, so there is no obvious need to make this calls from client (as to my current knowledge).
           * Maybe ist because of 'reloadbehavior'.
           */
          if (!process?.env?.APP_I18NNEXT_LOADPATH) {
            /** 
             * The check is here to remove log output in production. if you need to debug this in production, modify the condition
             */
            if (environment === 'development') {
              console.log('locale load requested from client. skipping.') //fix in the future, so that it can be loaded. rigth now not required because locales are always already loaded by next ssr
            }
            return;
          }

          const requestOptions = {
            ...options.requestOptions, 
            headers: {
              ...options.customHeaders
            }
          }
          
          fetch(url, requestOptions)
          .then(res => {
            if (res.status != 200) {
              console.error('locale load error response status: ' +res.status);
              callback(null, { status: res.status }) //return in a way so that backend makes retry
            } else {
              return res.json() 
            }
          })
          .then(data => {
            try {
              callback(null, { status: 200, data })
            } catch (error) {
              console.error('callback error', error);
              throw error
            }
          })
          .catch((error) => {
            const windowInfo = typeof window !== 'undefined' ? window.location.host + ' ' + window.location.pathname : 'no window info';
            console.error('Error loading locale file: fetch failed. error message: '+ error?.message +'. url: ' + url + '. windowInfo:' + windowInfo);
            callback(null, { status: null }) //return in a way so that backend makes retry
          });

        },
        reloadInterval: 60000
      },
    },
    serializeConfig: false,
    react: { 
      useSuspense: false,
    },
    reloadOnPrerender: process.env.APP_I18NNEXT_RELOAD_ON_PRERENDER,
    use: [I18NextHttpBackend]
};