export enum PageType {
    landing = 'landing', //full landing page 'outside' of web app. Is rendered using LandinPageModuleRenderer
    landingSimple = 'landingSimple', //a landing page with a predefined landing page structure, e.g. for product sub pages
    service = 'service',
    workspace = 'workspace',
    access = 'access',
    legal = 'legal',
    legalTerms = 'legalTerms',
    blogPost = 'blogPost',
}

export interface YoioPageSettings {
    pageType?: PageType
    /**
     * If try, noindex,follow meta will be set.
     */
    noindex?: boolean
    loadMe?: boolean
    loadMeLazy?: boolean
    /**
     * Default Page title. Will take precedence over app title.
     * Will be ignored on landing page subpages / childpages. For details see usePageMeta.
     */
    title?: string
    canonicalUrl?: string
    analyticsDisabled?: boolean
    signInAllowed?: boolean
    headMetaDefault?: boolean
    locale?: string
    hasTranslation?: boolean
    /**
     * Auto generate og image based on title and page description.
     * If no value given, the page renderer behavior is, that it will attempt to generate an image.
     */
    ogImageAutoGenerate?: boolean
    /**
     * If true, content should be fixed to screen height
     */
    screenHeightContent?: boolean
}

export interface YoioPageRequestOptions {
    /**
     * If true, props.app is loaded.
     * 
     * If false, app is not loaded in the request.
     * The behavior is that the app is then loaded by the browser in JavaScript.
     */
    preloadApp?: boolean
    /**
     * If true, page will consider the app.landingPageText and block access to that page, as long as the app has a landingPageText.
     */
    landingPageTextEnabled?: boolean
    /**
     * If set and has properties, the properties will be used instead of the default behavior.
     */
    yoioPageSettings?: YoioPageSettings
    trackPageViewed?: boolean
    /**
     * Next allows a request to have the locale in the URL 
     *  - /en/products 
     *  - or /de/products 
     * 
     * When this is true, the yoio page request handler should redirect the user to /products to strip the locale.
     * Necessary, because if the user calls /de/products, all links inside that page in the HTML will be generated with /de as well (or forward the user vie netx js with /de).
     * This is not desired for workspace pages. They should always be /products, even if the user has his language setting to German.
     * 
     * Usually this should be true if the page is also noindex.
     */
    disallowLocaleInUrl?: boolean
    locale?: string
    localeOptions?: PageLocaleOptions
    nextJsDestinationDirectory?: string 
}

export interface PageLocaleOptions {
    considerBrowserLocale?: boolean
}

export interface YoioPageProps {
    app?: any
    landingPage?: any
    yoioPageSettings?: YoioPageSettings
    errorResponse?: any
}