import { useIsMounted } from 'modules/picasso-ui/form/useIsMounted'
import { createElement, useRef, useState, useEffect } from 'react'

function useStaticContent(unhydratedAllowed) {

  const ref = useRef(null)
  
  const initialValue = useRef(unhydratedAllowed? typeof window === 'undefined' : true)
  const [renderCs, setRenderCs] = useState(initialValue.current)

  const isMounted = useIsMounted()

  useEffect(() => {
    //console.log('mount')
    if (!isMounted()) {
      return;
    }
    if (unhydratedAllowed && initialValue === true) {
      return;
    }

    //console.log('unhyAllowed: '+ unhydratedAllowed + ', renderCs: ' + renderCs)
    if (!unhydratedAllowed) {
      return;
    }
    // check if the innerHTML is empty as client side navigation
    // need to render the component without server-side backup
    const isEmpty = !ref.current || ref.current.innerHTML === ''
    if (isEmpty) {
      console.log('setRender')
      setRenderCs(true)
    }
  }, [])

/*   const handleRouteChanged = () => {
    const isEmpty = !ref.current || ref.current.innerHTML === ''
    if (isEmpty) {
      console.log('setRender')
      setRender(true)
    }
  }

  useEffect(() => {
    try {
      router.events.on('routeChangeComplete', handleRouteChanged)

      return () => {
        router.events.off('routeChangeComplete', handleRouteChanged)
      }
    } catch (error) {
      console.error(error)
    }
  }, [router]); */
  //console.log('useStaticContent return render ' + render)
  return [renderCs, ref]
}

export const Unhydrated = ({ children, element = 'div', unhydratedAllowed, ...props }) => {

  unhydratedAllowed = unhydratedAllowed !== null && unhydratedAllowed !== undefined ? unhydratedAllowed : true

  const [renderCs, ref] = useStaticContent(unhydratedAllowed)
 

  // if we're in the server or a spa navigation, just render it
  if (renderCs) {
    //console.log('render cs')

    if (!unhydratedAllowed) {
      // if unhydratedAllowed is not allowes, render children directly on server and client
      return children
    }

    // if unhydrated is allowed, render with intermediate element. 
    return createElement(element, {
      ...props,
      children,
    })
  }

  //console.log('unhy')
  // avoid re-render on the client
  return createElement(element, {
    ...props,
    ref,
    suppressHydrationWarning: true,
    dangerouslySetInnerHTML: { __html: '' },
  })
}