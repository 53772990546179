import 'styles.css';
import 'public/lp/lp.css';
import 'public/modules/modal-video/modal-video.min.css';

import { appWithTranslation } from 'next-i18next';
import NextI18nextConfig from 'next-i18next.config.js'

import createEmotionCache from 'conf/createEmotionCache';
import { CacheProvider } from '@emotion/react';
import { PropTypes } from 'prop-types';
import NextErrorView from 'next/error'
//import { YoioApp } from 'modules/yoio/component/YoioApp';
import { Unhydrated } from 'modules/yoio-modules/opt/Unhydrated';
import dynamic from 'next/dynamic';
import { YoioSWRConfig } from 'conf/YoioSWRConfig';
import { PageType } from 'modules/yoio-modules/core/PageTypes';
import Head from 'next/head';

/* const YoioApp = typeof window === 'undefined'
    ? require('modules/yoio/component/YoioApp').YoioApp
    : dynamic(() => import('modules/yoio/component/YoioApp').then((mod) => mod.YoioApp)) */

const YoioApp = dynamic(() => import('modules/yoio/component/YoioApp').then((mod) => mod.YoioApp))


const BugsnagWrapper = dynamic(() =>
  import('components/BugsnagWrapper').then((mod) => mod.BugsnagWrapper)
)

const disableBugsnagOnTypes = [PageType.landing, PageType.landingSimple, PageType.blogPost]
const roundButtonsOn = [PageType.landing, PageType.landingSimple, PageType.blogPost, PageType.legal, PageType.legalTerms]

//must be here to be replaced during compile
const environment = process.env.NEXT_PUBLIC_ENVIRONMENT
const bugsnagApiKey = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY
const bugsnagEnabledVar = process.env.NEXT_PUBLIC_BUGSNAG_ENABLED
const bugsnagEnabled = bugsnagEnabledVar != 'false' && bugsnagEnabledVar != false

const clientSideEmotionCache = createEmotionCache()

function MyApp({ Component, pageProps, emotionCache = clientSideEmotionCache, nonce }) {
  
  const showErrorView = pageProps?.showErrorView

  const wrapErrorBoundary = (wrap, children) => {
    if (wrap) {
      return <BugsnagWrapper environment={environment} apiKey={bugsnagApiKey}>{children}</BugsnagWrapper>
    } else {
      return children
    }
  }

  const bugsnagUseOnThisPage = (pageProps?.yoioPageSettings?.pageType===null||pageProps?.yoioPageSettings?.pageType===undefined) || !disableBugsnagOnTypes.includes(pageProps.yoioPageSettings.pageType)

  return wrapErrorBoundary(bugsnagEnabled && bugsnagUseOnThisPage,
      <CacheProvider value={emotionCache}>
{/*         <Head>
          <script
            dangerouslySetInnerHTML={{
              __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                `,
            }}
          />
        </Head> */}
      <YoioSWRConfig>
        <Unhydrated 
          unhydratedAllowed={environment !== 'development' && pageProps?.yoioPageSettings?.chunksLazyAllowed === true}
          >
            
            {(showErrorView) ? 
              <>  
                {showErrorView === 503 ?
                  <NextErrorView statusCode={null} title="There seems to be a temporary network issue. Please try again in a few minutes"/>
                  :
                  <NextErrorView statusCode={showErrorView}/>
                }
              </>
              :
              <YoioApp 
                initialApp={pageProps?.app} 
                yoioPageSettings={pageProps?.yoioPageSettings}
                yoioPageMeta={pageProps?.yoioPageMeta}
                landingPage={pageProps?.landingPage}
                userAgent={pageProps?.userAgent}
                nonce={nonce}>
                  {pageProps?.yoioPageSettings?.pageType && roundButtonsOn.includes(pageProps?.yoioPageSettings?.pageType) && <RoundButtons />}
                  <Component {...pageProps} />
              </YoioApp>
            }

        </Unhydrated>
      </YoioSWRConfig> 

      </CacheProvider>
    )
}

const RoundButtons = () =>
  <Head>
    <style
        dangerouslySetInnerHTML={{
        __html: `
            body button, body .lpCtaButton {
              border-radius: 99px !important;
            }
            `,
        }}
    >
    </style>
  </Head>

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};

export default appWithTranslation(MyApp, NextI18nextConfig);
