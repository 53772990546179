import { notNullNotUndefined } from "utils/objectUtils"
import { isString } from "utils/stringUtils"
import { useRouter } from "next/router"

export const notify = (error, callback, options) => {
    notNullNotUndefined(error)

    if (error.type && error.message && !error.response) {
        // is not instance JS error class
        error = new Error(JSON.stringify(error))
    }

    if (options?.message && error?.message && isString(error.message)) {
        error.message = options.message + '. ' + error.message
    }

    if (options?.disableLogging !== true) {
        if (error?.response) {
            console.log('error', error.message + '. url: ' + error.config?.url)
        } else {
            console.log('error', error.message)
        }
    }

    import('@bugsnag/js').then((Bugsnag)=>{
        if (Bugsnag._client) {
            Bugsnag.notify(error, (event)=>{
                event.severity = 'error'
                callback?.(event)
                if (error.response?.status) {
                    event.addMetadata("request", {
                        status: error.response.status
                    })
                    event.groupingHash = (event.groupingHash || event.errors[0]?.errorClass) + '_' + error.response.status + '_' + (event.severity||'')
                } else {
                    event.groupingHash = (event.groupingHash || event.errors[0]?.errorClass) + '_' + (event.severity||'')
                }
            })
        } else {
            console.error("(notify) " + error.message)
        }
    })
}

export const useErrorNotify = () => {

    const router = useRouter()

    const decorate = (event) => {

        let hostname = null
        if (typeof window !== 'undefined') {
            hostname = window?.location?.hostname;
        }

        event.addMetadata("window", {
            "path": router?.pathname,
            hostname,
        })
    }

    return {
        notify: (e, callback)=> {
            notify(e, (event)=> {
                callback?.(event)
                decorate(event)
            })
        },
        notifyInfo: (message, callback)=> {
            notify(new Error(message), (event)=> {
                event.severity = 'info'
                callback?.(event)
                decorate(event)

                
            }, {
                disableLogging: true,
            })
        },
    }

}