import { isStructuredCloneSupported } from "./featureDetects";
import { stableHash } from "./hashUtils";

export const notNullNotUndefined = (o) => {
    if (o === undefined) {
        throw Error('object cannot be undefined');
    }
    if (o === null) {
        throw Error('object cannot be null');
    }
};

export const isNullOrUndefined = (o) => {
    if (o === undefined) {
        return true;
    }
    if (o === null) {
        return true;
    }
    return false;
};

export const isHavingValue = (o) => {
    return isNullOrUndefined(o) === false;
};

export const cloneObject = (o) => {
    notNullNotUndefined(o);
    return JSON.parse(JSON.stringify(o));
};

export const isObjectNotEmpty = (o) => {
    return !isObjectEmpty(o);
}

export const isObjectEmpty = (o) => {
    if (!isHavingValue(o)) {
        return true;
    }
    if (o.length !== undefined && o.length !== null) {
        return o.length === 0;
    }
    return Object.keys(o).length === 0;
}

export const orElse = (val, fallback) => isHavingValue(val) ? val : fallback

export const validateIsBoolean = (val) => {
    if (val !== true && val !== false) {
        throw Error('object must be boolean');
    }
}

export const tryOrElse = (fnc, fallback) => {
    try {
        return fnc()
    } catch (error) {
        console.error(error)
        return fallback
    }
}

export const getFieldByPath = (o, s) => {
    notNullNotUndefined(o)
    notNullNotUndefined(s)

    try {
        if (!s.includes('.')) {
            return o[s]
        }
    
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            
            if (o == null) {
                return null;
            }
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    } catch (error) {
        //console.log('o', o)
        //console.log('s', s)
        throw error
    }


}

export const setFieldByPath = (obj, path, value) => {
    notNullNotUndefined(obj)
    notNullNotUndefined(path)
    
    const [head, ...rest] = path.split('.');

    if (!rest.length) {
        obj[head] = value
    } else {
        if (!obj[head]) {
            obj[head] = {}
        }
        setFieldByPath(obj[head], rest.join('.'), value)
    }
    return obj
}

export const deleteFieldByPath = (obj, path) => {
    notNullNotUndefined(obj)
    notNullNotUndefined(path)
    
    const [head, ...rest] = path.split('.');

    if (!rest.length) {
        delete obj[head]
    } else if (obj[head]) {
        deleteFieldByPath(obj[head], rest.join('.'))
    }
    return obj
}

export const isFunction = (functionToCheck) => {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export const isError = (e) => {
    return e && e.stack && e.message
}

export const isEqual = (obj, compare) => {
    if (isNullOrUndefined(obj)) {
        if (isNullOrUndefined(compare)) {
            return true
        } else {
            return false
        }
    }
    if (isNullOrUndefined(compare)) {
        return false
    }
    //console.log(JSON.stringify(obj), JSON.stringify(compare))
    return JSON.stringify(obj) === JSON.stringify(compare)
}

export const copyObj = (obj) => {
    if (obj === null) {
        return null
    }
    notNullNotUndefined(obj)
    return JSON.parse(JSON.stringify(obj))
}

export const pick = (obj, ...args) => ({
    ...args.reduce((res, key) => ({ ...res, [key]: obj[key] }), { })
})

export const structuredCloneOrFallback = (obj) => {
    return isStructuredCloneSupported() ? structuredClone(obj) : JSON.parse(JSON.stringify(obj))
}

export const findDifference = (a, b) => {
    notNullNotUndefined(a)
    notNullNotUndefined(b)

    if (stableHash(a) === stableHash(b)) {
        return null
    }

    const differentPaths = {}

    if (Array.isArray(a) && Array.isArray(b)) {
        for (let i=0;i<a.length;i++) {
            const left = a[i]
            const right = b[i]

            if (stableHash(left) !== stableHash(right)) {
                const subPaths = findDifference(left, right)
                Object.keys(subPaths).forEach((sp)=> {
                    const diff = subPaths[sp]

                    differentPaths['['+i+'].' + sp] = {
                        a: diff.a,
                        b: diff.b,
                    }
                })
            }
        }       
        return differentPaths 
    } else if (typeof a !== 'object' && typeof b !== 'object') {
        if (stableHash(a) !== stableHash(b)) {
            differentPaths[''] = {
                a: a,
                b: b
            }
        }
        return differentPaths
    }

    const allKeys = Object.keys(a).concat(Object.keys(b))

    var uniq = [ ...new Set(allKeys) ]

    uniq.forEach(k=>{
        const aVal = a[k]
        const bVal = b[k]


        if ((stableHash(aVal) !== stableHash(bVal)) || (JSON.stringify(aVal) !== JSON.stringify(bVal))) {
            console.log('different', k)
            if (typeof aVal === 'object' && typeof bVal === 'object' && aVal !== undefined && bVal !== undefined) {
                const subPaths = findDifference({...aVal}, {...bVal})
                Object.keys(subPaths).forEach((sp)=> {
                    const diff = subPaths[sp]

                    differentPaths[k + '.' + sp] = {
                        a: diff.a,
                        b: diff.b,
                    }
                })
            } else {
                differentPaths[k] = {
                    a: aVal,
                    b: bVal,
                }
            }
        } else {
            console.log('equal',k, stableHash(aVal) === stableHash(bVal))
        }
    })

    if (Object.keys(differentPaths).length === 0) {
/*         console.log(Object.keys(a))
        console.log(Object.keys(b))

        Object.keys(a).forEach(k=>{
            const aVal = a[k]
            const bVal = b[k]

            if (stableHash(aVal) !== stableHash(bVal)) {
                console.log(k, aVal, bVal)
            } else {
                console.log(k, stableHash(aVal), stableHash(bVal))
            }
        })

        console.log(a, b) */
        console.log(stableHash(a), stableHash(b))
    }

    return differentPaths;

}

/**
 * 
 * @param {*} obj 
 * @returns 
 *      Copy of the original object with null and undefined removed
 */
export function removeNullAndUndefinedFields(obj) {
    if (obj === null || obj === undefined || typeof obj !== 'object') {
        return;
    }

    obj = structuredCloneOrFallback(obj)

    for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined) {
            delete obj[key];
        } else if (typeof obj[key] === 'object') {
            obj[key] = removeNullAndUndefinedFields(obj[key]);
            if (Object.keys(obj[key]).length === 0) {
                delete obj[key];
            }
        }
    }
    return obj;
}

export const isObject = (obj) => obj !== null && typeof obj === 'object'

export const isObjectNonArray = (obj) => obj !== null && typeof obj === 'object' && !Array.isArray(obj);

export default { notNullNotUndefined, isNullOrUndefined, isHavingValue, cloneObject };
