import { useErrorNotify } from "modules/yoio/errorsService"
import { SWRConfig, useSWRConfig } from "swr"

export const YoioSWRConfig = ({children}) => {

    const swrDefaultConfig = useSWRConfig()

    const { notify } = useErrorNotify()

    return (
        <SWRConfig
            value={{
                onErrorRetry: (error, key, config, revalidate, revalidateOpts)=> {

                    // Don't notify on first retry. Often first request fails when user opens laptop, etc.
                    if (error.request && revalidateOpts?.retryCount >=2) {
                        // Axios network error. Server down or no internet.

                        const isNetworkError = !error.response
                        const severity = isNetworkError ? 'info' : 'error'

                        // Temporarily monitor this error
                        const originalMessage = error.message + ''
                        const errorMessage = 'SWR ' + error.message + '. retryCount=' + revalidateOpts?.retryCount
                        console.error(errorMessage)
                        notify(error, (event)=>{
                            event.errors[0].errorMessage = errorMessage
                            event.severity = severity
                            event.groupingHash = (event.groupingHash || '')+'SWR ' + originalMessage
                        })

                    }

                    swrDefaultConfig.onErrorRetry(error, key, config, revalidate, revalidateOpts)
                },
            }}
        >
          {children}
        </SWRConfig>
    )

}